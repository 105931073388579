import React, { useEffect } from 'react'

const Login = ({ title }) => {
    useEffect(() => {
        document.title = title
    }, [])
    return (
        <>
            <div className="as_loader">
                <img alt="" className="img-responsive" src="assets/images/loader.png" />
            </div>
            <section className="as_login_wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="ad_login_box">
                                <div className="row">
                                    <div className="col-lg-7 col-md-7 col-sm-12">
                                        <div className="as_login_slider as_padderTop70 as_padderBottom60 text-center">
                                            <div className="as_login_img">
                                                <img alt="" src="assets/images/login_slide1.png" />
                                            </div>
                                            <div className="as_login_img">
                                                <img alt="" src="assets/images/login_slide1.png" />
                                            </div>
                                            <div className="as_login_img">
                                                <img alt="" src="assets/images/login_slide1.png" />
                                            </div>
                                            <div className="as_login_img">
                                                <img alt="" src="assets/images/login_slide1.png" />
                                            </div>
                                            <div className="as_login_img">
                                                <img alt="" src="assets/images/login_slide1.png" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 col-md-7 col-sm-12">
                                        <div className="as_login_detail text-center">
                                            <a href="$">
                                                <img alt="" src="assets/images/logo.svg" />
                                            </a>
                                            <h1 className="as_padderTop50">
                                                Welcome back!
                                            </h1>
                                            <p className="as_padderBottom30">
                                                Please Login to your account
                                            </p>
                                            <form action="" className="d_block">
                                                <div className="form-group as_padderBottom10">
                                                    <input className="form-control" id="" name="" placeholder="Email" type="text" />
                                                </div>
                                                <div className="form-group">
                                                    <input className="form-control" id="" name="" placeholder="Password" type="password" />
                                                </div>
                                                <div className="form-group">
                                                    <div className="as_login_data">
                                                        <label>
                                                            Keep Me Signed In
                                                            <input name="as_remember_me" type="checkbox" value="" />
                                                            <span className="checkmark">
                                                            </span>
                                                        </label>
                                                        <a href="#">
                                                            Forgot password ?
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="text-center as_padderTop20">
                                                    <a className="as_btn" href="/">
                                                        Sign in
                                                    </a>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Login
