import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Login from '../screens/Login'
import NotFound from '../screens/Notfound'
import { AuthProvider } from '../context/AuthContext'
import { NetworkProvider } from '../context/NetworkProvider'
import { ProfileProvider } from '../context/ProfileContext'
import NotProtectedRoute from '../components/NotProtectedRoute'
import ProtectedRoute from '../components/ProtectedRoute'
import Dashboard from '../screens/Dashboard'
import ShopSingle from '../screens/ShopSingle'
import Shop from '../screens/Shop'
import Blog from '../screens/Blog'
import BlogView from '../screens/BlogView'
import Aboutus from '../screens/Aboutus'
import Contactus from '../screens/Contactus'
import ScrollToTop from '../Helpers/ScrollToTop'

const RoutesComponent = () => {
    return (
        <ProfileProvider>
            <NetworkProvider>
                <AuthProvider>
                    <Router basename="/">
                        <ScrollToTop />
                        <Routes>
                            <Route path="/login" element={<Login title='Login' />} />
                            <Route exact path="/" element={<ProtectedRoute><Dashboard title='Dashboard' /></ProtectedRoute>} />
                            <Route path="/products" element={<ProtectedRoute><Shop title='Shop' /></ProtectedRoute>} />
                            <Route path="/products/view/:id" element={<ProtectedRoute><ShopSingle title='Product View' /></ProtectedRoute>} />

                            <Route path="/blogs" element={<ProtectedRoute><Blog title='Blogs' /></ProtectedRoute>} />
                            <Route path="/blogs/view/:id" element={<ProtectedRoute><BlogView title='Blog View' /></ProtectedRoute>} />

                            <Route path="/aboutus" element={<ProtectedRoute><Aboutus title='About US' /></ProtectedRoute>} />
                            <Route path="/contactus" element={<ProtectedRoute><Contactus title='Contact US' /></ProtectedRoute>} />
                            <Route path="/notfound" element={<ProtectedRoute><NotFound title='Not Found' /></ProtectedRoute>} />
                            <Route path="*" element={<NotFound />} />
                        </Routes>
                    </Router>
                </AuthProvider>
            </NetworkProvider>
        </ProfileProvider>
    )
}

export default RoutesComponent
