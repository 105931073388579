import React from 'react'
import { Link, useLocation } from 'react-router-dom'

const Menu = () => {
    const location = useLocation()

    // Check if the current path matches the given path
    const isActive = (path) => {
        return location.pathname === path ? 'active' : ''
    }

    return (
        <div className="as_menu">
            <ul>
                <li>
                    <a className={isActive('/')} href="/">
                        home
                    </a>
                </li>
                
                <li>
                    <Link className={isActive('/products')} to="/products">
                        shop
                    </Link>
                </li>
                <li>
                    <Link className={isActive('/blogs')} to="/blogs">
                        blog
                    </Link>
                </li>
                <li>
                    <Link className={isActive('/aboutus')} to="/aboutus">
                        about us
                    </Link>
                </li>
                <li>
                    <Link className={isActive('/contactus')} to="/contactus">
                        contact
                    </Link>
                </li>
            </ul>
        </div>
    )
}

export default Menu
